<template>
	<div>
    <div hidden v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'">
      <v-row>
<!--        TODO this is not a good approach to have dialogs inside the component-->
        <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
          <v-card class="pa-7">
            <v-card-title class="d-flex justify-center align-center">
              <v-row  dense>
                <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
                </v-col>
                <v-col cols="12">
                  <span>{{ $t('deleteQuestion') }}</span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
            <v-card-actions class="mb-2">
              <v-row dense>
                <v-col cols="12">
                  <v-btn block depressed color="red" class="text-color-white"  @click="deleteUserConfirm">{{ $t('deleteField') }}</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn outlined depressed color="black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
					<v-data-table
						v-if="filteredHospitalAdmins"
            class="pa-1 pointer"
						:loading="loading"
						:headers="headers"
						:headers-length="headers.length"
						:search="appBarSearch"
						:no-data-text="$t('no-data-available')"
						:loading-text="$t('loading')"
						:items="filteredHospitalAdmins"
						sort-by="id"
						item-key="id"
						fixed-header
						dense
						@click:row="selectedHospitalAdminRow"
						:height="pageHeight(120)"
            :footer-props="footerProps"
            :items-per-page="-1"
						>
							<template v-slot:item="{ item }">
								<tr @click="selectedHospitalAdminRow(item)">
									<td class="text-left font-size12-5 font-weight-medium">
                    {{ item.firstName }} {{ item.lastName }}
									</td>
                  <td class="text-left font-size12-5 font-weight-medium">
                    {{ item?.roleData?.roleNameForDisplay }}
                  </td>
									<td class="text-left font-size12-5 font-weight-medium">
                  {{ item?.hospitalAssignedData === null ? "" : item?.hospitalAssignedData?.hospitalName }}
									</td>
									<td class="text-left font-size12-5 font-weight-medium">
                    {{ item.email }}
									</td>
									<td class="text-left font-size12-5 font-weight-medium">
                    {{ item.phone }}
									</td>
									<td>
                    <div class="d-flex align-center justify-end">
										<v-icon small class="mr-4" :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
										<v-menu
											left
											bottom
                      class="py-0"
											offset-y
											transition="slide-x-transition"
										>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                            icon
                            color="black"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <span class="mdi mdi-18px mdi-dots-horizontal "></span>
                        </v-btn>
                      </template >

                      <v-list class="px-0" dense >
												<v-list-item class="py-0 my-0" link dense @click="handleActionView(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon class="icon-img mdi-18px" color="black">mdi-eye</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="font-bold font-size14">{{$t("view")}}</v-list-item-content>
												</v-list-item>
												<v-list-item link dense @click="handleToggleStatus(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon class="icon-img mdi-18px" color="black">mdi-refresh</v-icon>
                          </v-list-item-icon>
													<v-list-item-content class="font-bold font-size14" >{{ $t('change') }} {{ $t('status') }}</v-list-item-content>
												</v-list-item>
												<v-list-item link dense @click="handleActionSendEmail(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon class="icon-img mdi-18px" color="black">mdi-lock-reset</v-icon>
                          </v-list-item-icon>
													<v-list-item-content class="font-bold font-size14" v-if="item.status !== 1" label @click.stop="sendActivationEmail(item, 'USER_CONFIRMATION')">{{ $t('send-activation-email') }}</v-list-item-content>
													<v-list-item-content class="font-bold font-size14 text-left" v-else label @click.stop="sendActivationEmail(item, 'RESET_PASSWORD')">{{ $t('send-reset-password-email') }}</v-list-item-content>
												</v-list-item>
                        <v-divider/>
												<v-list-item v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'" link dense @click="handleActionDelete(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon color="red">mdi-trash-can-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="font-bold font-size14" >{{ $t('delete') }}</v-list-item-content>
												</v-list-item>
											</v-list>
										</v-menu>
                    </div>
									</td>
								</tr>
							</template>
					</v-data-table>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
	data () {
		return {
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      loading: true,
			headerClass: 'color-header',
			selectedItem: {},
			dialogDelete: false,
		};
	},
	computed: {
		...mapGetters({
			roleName: 'authentication/getRole',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
			appBarMedicalInstitution: 'filterbar/getMedicalInstitution',
			appBarUserStatusVerifier: 'filterbar/getUserStatusVerifier',
			appBarRole: 'filterbar/getRole',
		}),
		...mapState({
			organizationAdmins: (state) => state.users.organizationAdmins,
			hospitalAdminUsers: (state) => state.users.hospitalAdmins,
			caregivers: (state) => state.users.caregivers,
			allVerifiers: (state) => state.verifiers.allverifires,
			allRoles: (state) => state.roles.roles,
      authenticated: state => state.authentication.userData,
      partners: (state) => state.users.partners,

		}),
		headers () {
			var headers = [];
			headers = [
				{
					text: this.$t('name'),
					value: 'firstName',
					class: this.headerClass,
				},
        {
          text: this.$t('role'),
          value: 'hospitalAssignedData?.roleNameForDisplay',
          class: this.headerClass,
        },
				{
					text: this.$t('Office'),
					value: 'hospitalAssignedData?.hospitalName',
					class: this.headerClass,
				},

				{
					text: this.$t('header-email'),
					value: 'email',
					class: this.headerClass,
				},
				{
					text: this.$t('header-phone'),
					value: 'phone',
					class: this.headerClass,
				},
				{
					text: '',
					value: '',
					class: this.headerClass,
          sortable: false,
				},
			];
			return headers;
		},
		filteredHospitalAdmins () {
			let calculateFileteredHospitalAdmins = [];
			if (this.organizationAdmins) {
				this.organizationAdmins.forEach((element) => {
					calculateFileteredHospitalAdmins.push(element);
				});
			}

			if (this.hospitalAdminUsers) {
				this.hospitalAdminUsers.forEach((element) => {
					calculateFileteredHospitalAdmins.push(element);
				});
			}
      if (this.partners) {
        this.partners.forEach((element) => {
          calculateFileteredHospitalAdmins.push(element);
        });
      }

			if (this.caregivers) {
				this.caregivers.forEach((element) => {
					calculateFileteredHospitalAdmins.push(element);
				});
			}

			if (this.appBarMedicalInstitution !== 0 && this.appBarMedicalInstitution !== null) {
				// filter by medicalInstitutionId
				calculateFileteredHospitalAdmins = calculateFileteredHospitalAdmins.filter(u => u.organizationId === this.appBarMedicalInstitution);
			}

			if (this.appBarUserStatusVerifier !== -99 && this.appBarUserStatusVerifier !== null) {
				// filter by userStatusId
				calculateFileteredHospitalAdmins = calculateFileteredHospitalAdmins.filter(u => u.status === this.appBarUserStatusVerifier);
			}

			if (this.appBarRole !== -99 && this.appBarRole !== null) {
				// filter by userStatusId
				calculateFileteredHospitalAdmins = calculateFileteredHospitalAdmins.filter(u => u.roleId === this.appBarRole);
			}
			return calculateFileteredHospitalAdmins;
		},
	},
	async mounted () {
		await this.getOrganizationAmdinUsers();
		await this.getHospitalAmdinsUsers();
		// await this.getCaregivers();
		await this.getAllVerifiers();
		await this.getAllRoles();
    await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', -99);
    await this.getPartners();
	},
	methods: {
    async getPartners () {
      const organizationId = this.roleName === 'SYSTEM_ADMIN' ? -1 : this.authenticated.organizationId;
      const body = {
        organizationId: organizationId,
      };

      await this.$store.dispatch('users/getPartners', body);
    },
		async getOrganizationAmdinUsers () {
			await this.$store.dispatch('users/getOrganizationAdmins').then(() => {
				this.loading = false;
			});
		},
		async getHospitalAmdinsUsers () {
			await this.$store.dispatch('users/getHospitalAdmins').then(() => {
				this.loading = false;
			});
		},
		async getCaregivers () {
			await this.$store.dispatch('users/getCaregivers').then(() => {
				this.loading = false;
			});
		},
		async getAllVerifiers () {
			// we call this API endpoint only if the store values are null
			if (this.allVerifiers == null) {
				await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
					// this.loading = false;
				});
			}
		},
		async getAllRoles () {
			// we call this API endpoint only if the store values are null
			if (this.allRoles == null) {
				await this.$store.dispatch('roles/getRoles').then(() => {
					// this.loading = false;
				});
			}
		},
		async editHospitalAdmin (item) {
			// await this.$store.commit('users/SELECTED_HOSPITAL_ADMIN', item); // get from the store, not from API call

			if (item.roleData.roleName === 'HOSPITAL_ADMIN') {
				this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN'); // set selectedHospitalAdmin = null
				this.$store.commit('users/SELECTED_CARE_GIVER'); // set selectedCaregiver = null
				this.$store.commit('users/SELECTED_PARTNER'); // set selectedCaregiver = null

				await this.$store.commit('users/SELECTED_HOSPITAL_ADMIN', item); // get from the store, not from API call
			} else if (item.roleData.roleName === 'ORGANIZATION_ADMIN') {
				this.$store.commit('users/SELECTED_HOSPITAL_ADMIN'); // set selectedHospitalAdmin = null
				this.$store.commit('users/SELECTED_CARE_GIVER'); // set selectedCaregiver = null
        this.$store.commit('users/SELECTED_PARTNER'); // set selectedCaregiver = null

				await this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN', item); // get from the store, not from API call
			} else if (item.roleData.roleName === 'CARE_GIVER') {
				this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN'); // set selectedHospitalAdmin = null
				this.$store.commit('users/SELECTED_HOSPITAL_ADMIN'); // set selectedHospitalAdmin = null
        this.$store.commit('users/SELECTED_PARTNER'); // set selectedCaregiver = null

				await this.$store.commit('users/SELECTED_CARE_GIVER', item); // get from the store, not from API call
			} else if (item.roleData.roleName === 'PARTNER') {
        this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN'); // set selectedHospitalAdmin = null
        this.$store.commit('users/SELECTED_HOSPITAL_ADMIN'); // set selectedHospitalAdmin = null

        await this.$store.commit('users/SELECTED_PARTNER', item); // get from the store, not from API call
      }
      // else if(item.roleDat)

			this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
		},
		selectedHospitalAdminRow (item) {
			this.editHospitalAdmin(item);
		},
		async sendActivationEmail (item, validationType) {
			if (validationType === null || validationType === undefined) {
                validationType = 'RESET_PASSWORD';
            }
			const sendValidationEmail = { email: item.email, validationType: validationType };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success-operation'));
					} else {
						showErrorAlert(this.$t('failed-operation'));
					}
				});
		},
		handleActionView (item) {
			this.editHospitalAdmin(item);
		},
		async handleToggleStatus (item) {
			if (item.status === -1) {
				// not validated user
				// showErrorAlert(this.$t('failed-operation'));
				showErrorAlert(this.$t('not-activated-user'));
				return;
			}

			const params = { userId: item.id, statusValueToSet: item.status === 1 ? 0 : 1 };
			await this.$store.dispatch('users/setUserStatus', params)
				.then(res => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success-operation'));

						if (item.roleId === 6) {
							this.getHospitalAmdinsUsers(); // reload the list of hospital admins
						} else if (item.roleId === 8) {
							this.getOrganizationAmdinUsers(); // reload the list of organization admins
						} else if (item.roleId === 7) {
							this.getCaregivers(); // reload the list of caregivers
						}
					} else {
						showErrorAlert(this.$t('failed-operation'));
					}
				});
		},
		handleActionSendEmail (item) {
			this.sendActivationEmail(item);
		},
		async handleActionDelete (item) {
			this.dialogDelete = true;
			this.selectedItem = item;
		},
		async deleteUserConfirm () {
			await this.$store.dispatch('users/deleteUser2', this.selectedItem)
				.then(res => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success-operation'));

						if (this.selectedItem.roleId === 6) {
							this.getHospitalAmdinsUsers(); // reload the list of hospital admins
						} else if (this.selectedItem.roleId === 8) {
							this.getOrganizationAmdinUsers(); // reload the list of organization admins
						} else if (this.selectedItem.roleId === 7) {
							this.getCaregivers(); // reload the list of caregivers
						} else if (this.selectedItem.roleId === 9) {
              this.getPartners(); // reload the list of partners
            }
						this.closeDeleteDialog();
					} else {
						showErrorAlert(this.$t('failed-operation'));
					}
				});
		},
		closeDeleteDialog () {
			this.dialogDelete = false;
			this.selectedItem = {};
		},
	},
  beforeDestroy () {
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
  },
};
</script>
<style>

</style>
